import React from 'react'
import { Link } from 'react-router-dom'

import './ServiceCard.css'

const ServiceCard = props => {
  return (
    <Link to={props.data.link} className='servicecard-container'>
        <img src={props.data.image} />
        <div className='servicecard-description'>
            <p>{props.data.name}</p>
        </div>
    </Link>
  )
}

export default ServiceCard