import React, {useEffect} from 'react'

import './HomePage.css'
import { Service } from "../../utils/Data"
import BackGround from '../../components/BackGround/BackGround'
import HomePageSmallIcon1 from '../../assets/homepage-smallicon1.png'
import HomePageSmallIcon2 from '../../assets/homepage-smallicon2.png'
import HomePageSmallIcon3 from '../../assets/homepage-smallicon3.png'
import HomePageAboutImage1 from '../../assets/homepage-aboutus-image1.jpg'
import HomePageAboutImage2 from '../../assets/homepage-aboutus-image2.jpg'
import HomePageAboutUsSmallIcon1 from '../../assets/homepage-aboutus-smallicon1.png'
import HomePageAboutUsSmallIcon2 from '../../assets/homepage-aboutus-smallicon2.png'
import HomePageAboutUsSmallIcon3 from '../../assets/homepage-aboutus-smallicon3.png'
import ServiceCard from '../../components/ServiceCard/ServiceCard'

const HomePage = () => {

  // useEffect(() => {
  //   window.scrollTo(0, 0)
  // }, [])

  return (
    <div className='homepage-maincontainer'>
      <div className='homepage-introcontainer'>
        <div className='homepage-introimage-container'>
          <div className='homepage-introimages'>
            <BackGround />
          </div>
          <div className='homepage-introimage-content'>
            <div className='homepage-introimage-subcontent'>
              <h5>WELCOME</h5>
              <h1>One Stop Solution For All Your Financial Freedom </h1>
              {/* <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo.</p> */}
              <a href='#'>DISCOVER MORE</a>
            </div>
          </div>
        </div>
        <div className='homepage-introcontainer-blocks'>
          <div className='homepage-introcontainer-blocks-subcontainer'>
            <div className='homepage-introcontainer-block'>
              <img alt='small icon 1' src={HomePageSmallIcon1} />
              <h2>Support 24/7</h2>
              <p>We provide good quality of communication services</p>
            </div>
            <div className='homepage-introcontainer-block'>
              <img alt='small icon 2' src={HomePageSmallIcon2} />
              <h2>Providing Quality Service</h2>
              <p>We provide good and quality services to our clients</p>
            </div>
            <div className='homepage-introcontainer-block'>
              <img alt='small icon 3' src={HomePageSmallIcon3} />
              <h2>Many Valuable Ideas</h2>
              <p>We will help you to grow and add valuable things to our clients</p>
            </div>
          </div>
        </div>
      </div>
      <div className='homepage-aboutus-maincontainer'>
        <div className='homepage-aboutus-subcontainer'>
          <div className='homepage-aboutus-imagecontainer'>
            <div className='homepage-aboutus-imageone'>
              <img alt='Home Page About One' src={HomePageAboutImage2} />
            </div>
            <div className='homepage-aboutus-imagetwo'>
              <img alt='Home Page About Two' src={HomePageAboutImage1} />
            </div>
          </div>
          <div className='homepage-aboutus-contentcontainer'>
            <h5>ABOUT US</h5>
            <h1>Providing convenience in your business finances is our responsibility</h1>
            <p className='quote-text'>”We are provided all types of fiancial services like investments in mutual funds, sip, lumsum investments, goal base invesments, term plan, mediclaim, health insurence, all types of insurance plan, demat account opening share market- commodity market, ipo, investor awareness program“</p>
            <p>We are in this field from last 11 years around and our teaching skills of share market and invesments program are gain by students from last 5 years around from which they have gain maximum benefits of investments.</p>
            <hr />
            <div className='homepage-aboutus-content-boxcontainer'>
              <div className='homepage-aboutus-content-box'>
                <img src={HomePageAboutUsSmallIcon1} />
                <h1>500<span>+</span></h1>
                <h6>Clients/Investor</h6>
              </div>
              <div className='homepage-aboutus-content-box'>
                <img src={HomePageAboutUsSmallIcon2} />
                <h1>200<span>+</span></h1>
                <h6>Client Reviews</h6>
              </div>
              <div className='homepage-aboutus-content-box'>
                <img src={HomePageAboutUsSmallIcon3} />
                <h1>11<span>+</span></h1>
                <h6>Experience</h6>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='homepage-trainingmaincontainer'>
        <div className='homepage-training-subcontainer'>
          <h5>OUR SERVICES</h5>
          <h2>Explore more about the quality service that we provide</h2>
          {/* <p>This is our courses that we teach</p> */}
          <div className='homepage-training-cardcontainer'>
            {Service.map(data => <ServiceCard data={data} />)}
          </div>
        </div>
      </div>
      <div className='homepage-missioncontainer'>
        <div className='homepage-mission-imagecontainer'>
        </div>
        <div className='homepage-mission-contentcontainer'>
          <div className='homepage-mission-missioncontainer'>
            <h1>Our Mission</h1>
            <p>Our mission is to aware every indian from importence of the knowledge of all types invesments and insurance for full fill your and your family fiancial goal.  make india and indians fiancial rich. this is to aware people that types of investments.  share market is no gambling, it's a kind of instrument for investments and trading provide platform. first learn than investments or trading in share market.</p>
          </div>
          <div className='homepage-mission-visioncontainer'>
            <h1>Our Vision</h1>
            <p>We are providing guidence of invesment needs so you and your family achive fiancial futures goals. many options avalble of investments plans so first understand than choose.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HomePage