import Image1 from "../assets/Training1.jpg"
import Image2 from "../assets/Training2.jpg"
import Image3 from "../assets/Training3.jpg"
import Image4 from '../assets/Training4.jpg'
import Image5 from '../assets/Training5.jpg'
import Image6 from "../assets/Training6.jpg"
import Investment from "../assets/investment.jpg"
import Insurance from "../assets/insurance.jpg"
import LearnStockMarket from '../assets/learnstockmarket.jpg'

export const Data = [
    {
        id: 1,
        name: "Basic Course",
        image: Image1,
        details: ["WHAT IS DEEMAT ACOUNT, IPO, BSE, NSE, SEBI, NIFTY, SENSEX.", "INVESTMENT, TRADING PLAT FORMS", "WHAT IS SHARE MARKET?", "HOW TO USE STOCK MARKET?", "HOW TO INVEST IN MARKET?", "INVESTMENT STRETGY", "GLOBAL MARKETS", "MONEY MANAGEMENT", "TIME MANAGEMENT", "SEGMENTS EQUITY & COMMODITY MARKET", "RULES OF INVESMENTS AND TRADING", "RISK AND MONEY MANAGMENTS", "BUY SELL STOP LOSS TGT EXIT", "MUTUAL FUND SIP LUMSUM", "BASIC OF FUNDAMANTAL & TECHNICAL ANALYSIS"]
    },
    {
        id: 2,
        name: "Full Course",
        image: Image2,
        details: ["DEEMAT ACOUNT, BSE, NSE, SEBI, IPO", "STOCK MARKET LIKE BUSINESS AND INVESTMENTS PLATFORM", "INVESTOR , TRADER UNDERSTAND OWN", "WHAT IS SHARE MARKET?", "HOW TO USE STOCK MARKET?", "HOW TO INVEST IN MARKET?", "INVESTMENT STRETGY", "RISK MANAGMENT", "MONEY MANAGEMENT IN STOCK MARKET", "TIME MANAGEMENT IN S.M.", "SEGMENTS EQUITY & COMMODITY MARKET", "BUY SELL STOP LOSS TGT EXIT", "INVESTMENT, TRADING STRETGY", "MUTUAL FUND SIP DETAILS", "FANDAMANTAL & TECHNICAL  ANALYSIS", "RULES & REGULATION OF TRADING AND INVESTMENTS", "TRICKS OF TRADING AND INVESMENTS", "HOW TO FIND TREND IN S.M.", "CHART CANDLE STICK TECHNICAL ANALYSIS", "EMOTIONAL VS PROFFESIONAL ", "TRADER OR INVESTER ", "DARR OR LALACH", "NEWS, RESULTS, EVENT OF SHARE MARKET", "PORFOLIO MANAGEMENTS", "FOR ENTER ANY BUSINESS ALWAYS FIRST LEARN THAN START.", "EASY WAY TO LEARN"]
    },
    {
        id: 3,
        name: "Student Course",
        image: Image3,
        details: ["FINANCE & INVESTMENTS COURSE FOR STUDENT", "IT IS TIME TO LEARN YOUR CHILD FOR FINANCE LEARNS FOR LIFE.", "NOW USE OF TIME AND UNDERSTAND ABOUT INVESTMENTS (INVESTMENTS IMPORTENCE IN LIFE)", "VALUE OF FAINCIAL PLANING MF, SIP, INSURANCE, FD, POST GOLD REAL STATE INVESTMENTS INSTRUMENTS", "THE VALUE OF MONEY EARNED, EFFECTIVE INVESTMENTS AND UTILISATION OF THE MONEY GIVEN.", "UNDERSTAND OF CHANNELS OF INVESMENTS. ", "HOW TO FINACIAL FREEDOM IN LIFE.", "LIFE MANAGEMENT, TIME MANAGEMENT, MONEY MANAGEMENT", "VALUE OF MONEY & VALUE OF RELATIONSHIP", "EXPERENCE IS EVERY IMPORTANT THING", "MATURITY (EXPERENCE) IS MORE IMPORTENT NOT IS AGE ", "VALUE OF STUDY, FRIENDS, FAMILY, MONEY", "RESPONSBILITES FOR FAMILY SOCIETY, COUNTRY?", "USE OF MOBILE INTERNET", "OUR NATURE AND BEHAVIOUR", "PERSONALITY DEVELOPMENT ", "OWN AND FAMILY GOAL, DREAM.", "HOBBY, ACTIVITY, READING, SPEAKING.", "HOW TO BE HAPPYNESS & STRESS CONTROL", "EXPENSES VS ASSETS", "INFLATIONS VS INVESTMENTS"]
    },
    {
        id: 4,
        name: "Exam Related Course",
        image: Image4,
        details: ["DEEMAT ACOUNT, BSE, NSE, SEBI, SENSEX, NIFTY.", "IPO, INVESTMENT, TRADING BASIC", "HOW TO USE STOCK MARKET?", "HOW TO INVEST IN MARKET?", "INVESTMENT STRETGY", "DIVIDENT, BONUS, BUYBACK, RIGHT ISSUE.", "MONEY MANAGEMENT", "TIME MANAGEMENT, RISK MANAGEMENT.", "SEGMENTS EQUITY & COMMODITY MARKET", "BUY SELL STOP LOSS TGT EXIT", "MUTUAL FUND SIP LUMSUM", "BASIC OF FUNDAMANTAL & TECHNICAL ANALYSIS", "RULES OF TRADING AND INVESTMENTS", "OPTION CHAIN, TREND, EVENTS, MARKETCAP.", "NISM EXAM FUT DERIVATIVE OR MUTUAL FUNDS PREPARATION."]
    },
    {
        id: 5,
        name: "Intraday Option Course",
        image: Image5,
        details: ["GOLDEN RULES OF TRADING", "HOW TO USE STOCK MARKET FOR TRADING?","TRADING STRATEGY & TRICKS","HOW TO USE GLOBAL MARKETS FOR TRADING? ","MONEY MANAGEMENT AND TIME, RISK MANAGEMENT","SEGMENTS EQUITY & FUT. OPTIONS","BUY SELL STOP LOSS TGT EXIT FIND","MINIMUM RISK FOR TRADING STRATEGY","NIFTY, BANK NIFTY, STOCK OPTION TRADING.","BASIC OF TECHNICAL ANALYSIS","OPTION CHAIN, EXPIRY WEEKLY, MONTHLY.","SUPPORT & RESISTENCE (LEVEL), TRADE WITH TREND"]
    },
    {
        id: 6,
        name: "Investor Awareness Program ",
        image: Image6,
        details: ["WHAT IS INVESMENTS?","TYPES OF INVESTMENTS","IMPORTENCE OF INVESTMENTS","MUTUAL FUND, SHARE MARKET, TERM PLAN","INSURANCE, POST, FD’S, GOLD, REAL STATE.","BENIFITE OF RIGHT ASEET CLASS CHOOSE FOR INVESTMENTS","SAVING VS EXPENSE","SAVING VS INVESTMENTS","AWARENSS ABOUT INVESMENTS PLAT FORMS","TIME VALUE, MONEY VALUE", "GOAL BASE INVESTMENTS."]
    }
]

export const Service = [
    {
        id: 1,
        name: "Investment",
        image: Investment,
        link: "/our-products"
    },
    {
        id: 2,
        name: "Insurance",
        image: Insurance,
        link: "/our-products"
    }
    // {
    //     id: 3,
    //     name: "Courses",
    //     image: LearnStockMarket,
    //     link: "/our-courses"
    // }
]

export const Products = [
    {
        id: Math.random(),
        name: "DEMAT ACCOUT OPENING",
        link: "#"
    },
    {
        id: Math.random(),
        name: "SHARE & COMMODITY MARKET",
        link: "#"
    },
    {
        id: Math.random(),
        name: "IPO SIP PLANNER",
        link: "#"
    },
    {
        id: Math.random(),
        name: "MUTUAL FUND SIP LUMSUM INVESTMENTS ",
        link: "https://fundzbazar.com/Link/D4SjyWDouuo"
    },
    {
        id: Math.random(),
        name: "GOAL BASE INVESMENTS SIP OR LUMSUM INVESTMENTS",
        link: "#"
    },
    {
        id: Math.random(),
        name: "CHILD’S EDUCATION, MARRIGE BASE INVESTMENT PLAN SIP",
        link: "#"
    },
    {
        id: Math.random(),
        name: "RETIREMENT BASE INVESTMENTS PLAN SIP",
        link: "#"
    },
    {
        id: Math.random(),
        name: "FUTURE PLANING SIP INVESTMENTS",
        link: "#"
    },
    {
        id: Math.random(),
        name: "HEALTH INSURANCE (MEDICLAM POLICY)",
        link: "#"
    },
    {
        id: Math.random(),
        name: "TERM PLAN",
        link: "#"
    },
    {
        id: Math.random(),
        name: "ALL TYPES OF PLAN AND INSURANCE",
        link: "#"
    }
]