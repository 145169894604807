import { React, useState, useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-toastify/dist/ReactToastify.min.css';
import emailjs from "@emailjs/browser";

import './ContactPage.css'
import DisclaimerPage from '../DisclaimerPage/DisclaimerPage'
import LocationIcon from '../../assets/locationIcon.png'
import EmailIcon from '../../assets/emailIcon.png'

const ContactPage = () => {
    const [formData, setFormData] = useState({})

    useEffect(() => emailjs.init("ghaJ7H3IP11lJ-fS3"), []);

    const formDataHandler = e => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value })
    }

    const SubmitHandler = async () => {
        toast.success("Response recorded. We will contact you soon.")
        setFormData({ Name: "", Email: "", Company: "", Phone: "", Message: "" })
        const serviceId = "service_ssosbnd";
        const templateId = "template_fix5fv9";
        await emailjs.send(serviceId, templateId, {
            from_email: formData.Email,
            from_name: formData.Name,
            message: formData.Message,
            phone_number: formData.Phone,
            company_name: formData.Company
           });
    }

    return (
        <>
            <div className='contactpage-maincontainer'>
                <ToastContainer />
                <div className='contactpage-imagecontainer'>
                    <div>
                        <h1>Contact Us</h1>
                    </div>
                </div>
                <div className='contactpage-form-maincontainer'>
                    <div className='contactpage-form-subcontainer'>
                        <div className='contactform-container'>
                            <div className='contactform-form'>
                                <h1>Have Question? <br /> Get in Touch</h1>
                                <div className='contactform-flexcontainer'>
                                    <div className='contactform-inputcontainer'>
                                        <label>Name</label>
                                        <input value={formData.Name} name='Name' onChange={(e) => formDataHandler(e)} placeholder='Name' />
                                    </div>
                                    <div className='contactform-inputcontainer'>
                                        <label>Email</label>
                                        <input value={formData.Email} name='Email' onChange={(e) => formDataHandler(e)} placeholder='Email' />
                                    </div>
                                </div>
                                <div className='contactform-flexcontainer'>
                                    <div className='contactform-inputcontainer'>
                                        <label>Company</label>
                                        <input value={formData.Company} name='Company' onChange={(e) => formDataHandler(e)} placeholder='Company' />
                                    </div>
                                    <div className='contactform-inputcontainer'>
                                        <label>Phone</label>
                                        <input value={formData.Phone} name='Phone' onChange={(e) => formDataHandler(e)} placeholder='Phone' />
                                    </div>
                                </div>
                                <div className='contactform-flexcontainer'>
                                    <div className='contactform-inputcontainer contactform-textarea'>
                                        <label>Message</label>
                                        <textarea value={formData.Message} name='Message' onChange={(e) => formDataHandler(e)} placeholder='Message' />
                                    </div>
                                </div>
                                <button onClick={SubmitHandler}>SUBMIT MESSAGE</button>
                            </div>
                        </div>
                        <div className='homepage-aboutus-contentcontainer contactform-detailcontainer'>
                            <h5>CONTACT US</h5>
                            <h1>Request a call back</h1>
                            <p>Feel free to get in touch with us. We are always open to discussing new offers, creative ideas or opportunities to be part of your visions</p>
                            <hr />
                            <div className='homepage-aboutus-content-boxcontainer'>
                                <div className='homepage-aboutus-content-box contactpage-content-box'>
                                    <img src={LocationIcon} />
                                    <h6>Office Address</h6>
                                    <p>S-55, Block -c, Silicon Shopper Udhana Dharwaja, Near South Zone, Office, Udhana, Surat - 394210</p>
                                </div>
                                <div className='homepage-aboutus-content-box contactpage-content-box'>
                                    <img src={EmailIcon} />
                                    <h6>Email Address</h6>
                                    <p>m5onestopsolution@gmail.com</p>
                                    <div>
                                        <span>9723201002</span>
                                        <span>7622982396</span>
                                        <span>0261-35644460</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <DisclaimerPage />
            </div>
        </>
    )
}

export default ContactPage