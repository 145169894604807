import { React, useState, useEffect } from 'react'
import { NavLink } from 'react-router-dom';
import FmdGoodOutlinedIcon from '@mui/icons-material/FmdGoodOutlined';
import PhoneOutlinedIcon from '@mui/icons-material/PhoneOutlined';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';
import { Spin as Hamburger } from 'hamburger-react'

import './Navbar.css'
import Logo from '../../assets/m5logo.jpg'

const Navbar = () => {
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        if (window.innerWidth < 480) {
            setIsOpen(false)
        } else {
            setIsOpen(true)
        }
    }, [])

    const CloseHandler = () => {
        if (window.innerWidth < 480) {
            setIsOpen(false)
        }
    }

    return (
        <div className='navbar-maincontainer'>
            <div className='navbar-subcontainer'>
                <div className='navbar-topcontainer'>
                    <div>
                        <p><FmdGoodOutlinedIcon />S-55, Silicon Shopper Udhana Dharwaja, Surat</p>
                        <p><PhoneOutlinedIcon />7622982396</p>
                    </div>
                    <div className='navbar-topcontainer-socialmedialogos'>
                        <a href='#'><div><FacebookRoundedIcon /></div></a>
                        <a href='#'><div><InstagramIcon /></div></a>
                        <a href='#'><div><TwitterIcon /></div></a>
                        <a href='#'><div><YouTubeIcon /></div></a>
                    </div>
                </div>
                <div className='navbar-separator'></div>
                <div className='navbar-bottomcontainer'>
                    <div className='navbar-bottomcontainer-logocontainer'>
                        <div><img src={Logo} alt="M5 Logo" /></div>
                        <h1>M5 One Stop Investment Solution</h1>
                    </div>
                    <div className='navbar-hamburger'><Hamburger color='#FF6637' toggled={isOpen} toggle={setIsOpen} /></div>
                    {isOpen && <div className='navbar-bottomcontainer-navlinks'>
                        <NavLink exact onClick={CloseHandler} activeClassName='activeClass' to='/'>Home</NavLink>
                        {/* <NavLink exact onClick={CloseHandler} activeClassName='activeClass' to='/our-courses'>Our Courses</NavLink> */}
                        <NavLink exact onClick={CloseHandler} activeClassName='activeClass' to='/our-products'>Our Products</NavLink>
                        <NavLink exact onClick={CloseHandler} activeClassName='activeClass' to='/contact-us'>Contact Us</NavLink>
                    </div>}
                </div>
            </div>
        </div>
    )
}

export default Navbar