import { React, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { Data } from "../../utils/Data"
import './TrainingDetailPage.css'

const TrainingDetailPage = () => {
    const [trainingDetails, setTrainingDetails] = useState({})
    const params = useParams()

    useEffect(() => {
        window.scrollTo(0, 0)
        const id = params.id;
        const obj = Data.filter(d => Number(d.id) === Number(id))
        setTrainingDetails(obj[0])
    }, [])

    return (
        <div className='trainingdetailpage-maincontainer'>
            <div className='trainingdetailpage-imagecontainer'>
                <div>
                    <h1>Training Details</h1>
                </div>
            </div>
            <div className='trainingdetailpage-detailmaincontainer'>
                <div className='trainingdetailpage-detailsubcontainer'>
                    <h2>{trainingDetails.name}</h2>
                    <h3>Course Outline</h3>
                    <ul>
                        {trainingDetails?.details?.map(d => <li key={Math.random()}><p>{d}</p></li>)}
                    </ul>
                    {/* <p>Course duration {trainingDetails.duration}</p> */}
                </div>
            </div>
        </div>
    )
}

export default TrainingDetailPage