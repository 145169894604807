import React from 'react'

import './ProductCard.css'

const ProductCard = props => {
  return (
    <div className='productcard-container'>
      <a href={props.data.link}>
        <div className='productcard-description'>
          <p>{props.data.name}</p>
        </div>
      </a>
    </div>
  )
}

export default ProductCard