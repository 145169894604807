import { useState, useRef, useEffect } from "react";

import "./BackGround.css";
import SliderImage1 from '../../assets/homepage-sliderimage1.jpg'
import SliderImage2 from '../../assets/homepage-sliderimage2.jpg'
import SliderImage3 from '../../assets/homepage-sliderimage3.jpg'
import SliderImage4 from '../../assets/homepage-sliderimage4.jpg'

const BackGround = () => {
  const images = [SliderImage1, SliderImage2, SliderImage3, SliderImage4];
  const [currentSlide, setCurrentSlide] = useState(0);

  let sliderInterval = useRef();

  let switchImages = () => {
    if (currentSlide < images.length - 1) {
      setCurrentSlide(currentSlide + 1);
    } else {
      setCurrentSlide(0);
    }
  };

  useEffect(() => {
    sliderInterval = setInterval(() => {
      switchImages();
    }, 5000);
    return () => {
      clearInterval(sliderInterval);
    };
  });

  return (
    <div className="imgWrapper">
      {images.map((img, index) => {
        return (
          <img
            src={img}
            className={
              index === currentSlide ? "imageActive homeImage" : "image"
            }
          />
        );
      })}
    </div>
  );
};

export default BackGround;
