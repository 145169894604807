import React, {useEffect} from 'react'

import './TrainingPage.css'
// import DisclaimerPage from '../DisclaimerPage/DisclaimerPage'
import {Data} from '../../utils/Data'
import TrainingCard from '../../components/TrainingCard/TrainingCard'

const TrainingPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <div className='trainingpage-maincontainer'>
            <div className='trainingpage-imagecontainer'>
                <div>
                    <h1>Our Courses</h1>
                </div>
            </div>
            <div className='trainingpage-cardcontainer'>
                <h5>Our Courses</h5>
                <h1>Explore our courses</h1>
                <div className='trainingpage-cards-maincontainer'>
                    <div className='trainingpage-cards-subcontainer'>
                        {Data.map(course => <TrainingCard data={course} />)}
                    </div>
                </div>
                <h1 className='trainingpage-manymoretext'>Many courses available for more details for investment  your and your family financial goals</h1>
                {/* <DisclaimerPage /> */}
            </div>
        </div>
    )
}

export default TrainingPage