import React, {useEffect} from 'react'

import './ProductPage.css'
import {Products} from '../../utils/Data'
import ProductCard from '../../components/ProductCard/ProductCard'

const ProductPage = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return (
        <div className='productpage-maincontainer'>
            <div className='productpage-imagecontainer'>
                <div>
                    <h1>Our Products</h1>
                </div>
            </div>
            <div className='productpage-cardcontainer'>
                <h5>OUR PRODUCTS</h5>
                <h1>Explore Our Products</h1>
                <div className='productpage-cards-maincontainer'>
                    <div className='productpage-cards-subcontainer'>
                        {Products.map(member => <ProductCard data={member} />)}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductPage