import React from 'react'
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import YouTubeIcon from '@mui/icons-material/YouTube';

import './Footer.css'
import Logo from '../../assets/m5logo.jpg'

const Footer = () => {
  return (
    <div className='footer-maincontainer'>
      <div className='footer-subcontainer'>
        <div className='footer-topcontainer'>
          <div className='footer-topcontainer-logocontainer'>
            <div><img src={Logo} alt="M5 Logo" /></div>
            <h1>M5 One Stop Investment Solution</h1>
          </div>
          <div>
            <h4>Find Us</h4>
            <hr />
            <p>S-55, Block -c, Silicon Shopper <br /> Udhana Dharwaja, Near South Zone, <br /> Office, Udhana, Surat - 394210</p>
            <p>9723201002</p>
            <p>7622982396</p>
            <p>0261-35644460</p>
            <p>m5onestopsolution@gmail.com</p>
          </div>
          <div>
            <h4>Our Services</h4>
            <hr />
            <p>Corporate Finance</p>
            <p>Strategy Consulting</p>
            <p>Transaction Support</p>
            <p>Accounting Advisory</p>
            <p>Digital Consulting</p>
          </div>
          <div>
            <h4>Follow Us</h4>
            <hr />
            <div className='footer-topcontainer-socialmedialogos'>
              <a href='#'><div><FacebookRoundedIcon /></div></a>
              <a href='#'><div><InstagramIcon /></div></a>
              <a href='#'><div><TwitterIcon /></div></a>
              <a href='#'><div><YouTubeIcon /></div></a>
            </div>
          </div>
        </div>
        <div className='footer-separator'></div>
        <div className='footer-bottomcontainer'>
          <p>Copyright 2023 © All Right Reserved</p>
        </div>
      </div>
    </div>
  )
}

export default Footer