import React from "react";
import { BrowserRouter as Routes, Route, Switch } from 'react-router-dom'

import HomePage from "./pages/HomePage/HomePage";
import Navbar from "./components/Navbar/Navbar";
import Footer from "./components/Footer/Footer";
import ContactPage from "./pages/ContactPage/ContactPage";
import ProductPage from "./pages/ProductPage/ProductPage";
import TrainingPage from "./pages/TrainingPage/TrainingPage";
import TrainingDetailPage from "./pages/TrainingDetailPage/TrainingDetailPage";

const App = () => {
  return (
    <Routes>
      <Navbar />
      <Switch>
        <Route exact path='/' >
          <HomePage />
        </Route>
        <Route exact path='/contact-us' >
          <ContactPage />
        </Route>
        <Route exact path='/our-products' >
          <ProductPage />
        </Route>
        <Route exact path='/our-courses' >
          <TrainingPage />
        </Route>
        <Route exact path='/training-details/:id' >
          <TrainingDetailPage />
        </Route>
      </Switch>
      <Footer />
    </Routes>
  );
}

export default App;
