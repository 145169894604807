import React from 'react'
import { Link } from 'react-router-dom'

import './TrainingCard.css'

const TrainingCard = props => {
  return (
    <Link to={`/training-details/${props.data.id}`} className='trainingcard-container'>
        <img src={props.data.image} />
        <div className='trainingcard-description'>
            <p>{props.data.name}</p>
        </div>
    </Link>
  )
}

export default TrainingCard